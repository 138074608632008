<template>
	<div class="practice">
		<el-tabs v-model="activeName" @tab-click="practiceTab" v-if="isList == 'list'">
		    <el-tab-pane label="练习模式" name="practice">
				<!-- <exercise-list :data="practiceList"></exercise-list> -->
			</el-tab-pane>
		    <el-tab-pane label="模拟考试" name="simulate">
				<!-- <exercise-list :data="simulateList"></exercise-list> -->
			</el-tab-pane>
		</el-tabs>
		<exercise-list v-if="isList == 'list'" :currpage="currpage" :data="practiceList" :total="total" :pageSize="pageSize" @order-chang-page="changePage" @start-practice="startPractice"></exercise-list>
		<practice-papers v-else-if="isList == 'papers'" :data="itemInfo" :titleSync.sync="isList"></practice-papers>
		<mock-exam v-else-if="isList == 'mock'" :data="itemInfo" :titleSync.sync="isList"></mock-exam>
	</div>
</template>

<script>
	import exerciseList from "/src/components/exerciseList";//列表页面
	import practicePapers from "/src/views/LearningCenter/practicePapers";//练习试卷
	import mockExam from "/src/views/LearningCenter/MockExam";//模拟考试
	export default {
		components: {
			exerciseList,
			practicePapers,
			mockExam
		},
		data() {
			return {
				activeName:"practice",//选项卡选中项
				practiceList:[],//练习模式列表
				total:0,
				pageSize:5,
				isList:"list",//是否为列表
				itemInfo:{},//当前课程信息
				currpage:1
			}
		},
		methods: {
			//获取练习列表
			getPractice(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/exerciseCourse.html", {
					token:window.sessionStorage.getItem('token'),
					page:this.currpage,
					limit:this.pageSize,
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.total = response.data.data.total;
						this.practiceList = response.data.data.data;
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//分页
			changePage(val){
				this.currpage = val;
				//获取订单
				this.getPractice()
			},
			//开始练习
			startPractice(item){
				if(this.activeName == "practice"){
					this.$http.post(this.PublicJs.publicPath + "/api/student.Info/exerciseData.html", {
						token:window.sessionStorage.getItem('token'),
						course_id:this.PublicJs.Encrypt(item.sig_course_id)
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							if(response.data.data.data.length){
								this.isList = "papers";
							}else{
								this.$message({
									message: "当前课程暂无试题，请咨询客服",
									type: 'warning',
									showClose: true
								});
							}
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
					
				}else{
					this.$http.post(this.PublicJs.publicPath + "/api/student.Info/mockExam.html", {
						token:window.sessionStorage.getItem('token'),
						course_id:this.PublicJs.Encrypt(item.sig_course_id)
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 0){
							if(response.data.data){
								this.isList = "mock";
							}else{
								this.$message({
									message: "当前课程暂无试题，请咨询客服",
									type: 'warning',
									showClose: true
								});
							}
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						if(response.status == 401){
							var that = this;
							this.$message({
								message: "您的账号在别处登录，请重新登录",
								type: 'warning',
								showClose: true
							});
							window.sessionStorage.removeItem('user_id')
							window.sessionStorage.removeItem('token')
							window.sessionStorage.removeItem('userinfo')
							setTimeout(function(){
								that.$router.go(0)
							},2000); 
							
						}
					});
				}
				this.itemInfo = item;
			},
			//点击选项卡
			practiceTab(){
				this.currpage = 1;
				this.isList = 'list';
				//获取练习列表
				this.getPractice()
			}
		},
		created() {
			//获取练习列表
			this.getPractice()
		}
	}
</script>

<style scoped="scoped">
	.practice,.el-tabs,.el-tab-pane {
		width: 100%;
		height: 100%;
		position: relative;
	}
	.el-tabs{
		padding-top: 60px;
	}
</style>
<style>
	.practice .el-tabs__content{
		width: 100%;
		height: 100%;
	}
	.practice .el-tabs__header{
		margin: 0;
		width: 100%;
		position: absolute;
		top: 0;
		left:0;
	}
	.practice .el-tabs__item{
		height: 60px;
		line-height: 60px;
		font-size: 16px;
		color: #333;
		text-align: center;
		outline: none;
	}
	.practice .el-tabs__nav-wrap::after{
		height: 1px;
		background-color: #E6E7EB;
	}
	.practice .el-tabs__active-bar{
		background-color:#5E7BF2;
		height: 3px;
	}
	.practice .el-tabs__item.is-active{
		font-weight: bold;
	}
	.practice .el-tabs--top .el-tabs__item.is-top:nth-child(2){
		padding-left: 20px;
	}
</style>
