<template>
	<div class="exerciseList_box">
		<div class="courseList" v-if="data.length">
			<div class="courseItem" v-for="(item,index) in data" :key="index">
				<img :src="item.cou_thumb" class="courseImg" />
				<div class="courseName">{{item.cou_name}}</div>
				<div class="AdoptBtn" v-if="item.sig_passtext == 'yes'">考试已通过</div>
				<div class="courseBtn" v-else @click="startPractice(item)">开始练习</div>
			</div>
			<div class="coursePaginat" v-if="total > pageSize">
				<el-pagination
				  background
				  layout="prev, pager, next"
				  :total="total"
				  :page-size="pageSize"
				  :current-page="currpage"
				  @current-change="changPage">
				</el-pagination>
			</div>
		</div>
		<div class="noData" v-else>
			<img src="@/views/images/noData.png" />
			<div class="noDataTips">
				<span>暂无数据</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			data:{
				type: Array
			},
			total:{
				type: Number
			},
			pageSize:{
				type: Number
			},
			currpage:{
				type: Number
			},
		},
		methods:{
			//分页跳转
			changPage(val){
				this.$emit('order-chang-page', val);
			},
			//开始练习
			startPractice(item){
				this.$emit('start-practice', item);
			},
		}
	}
</script>

<style scoped="scoped">
	.orderList_box{
		position: relative;
		width: 100%;
		height: 100%;
	}
	.noData{
		text-align: center;
		width: 100%;
		height: 236px;
		margin-top: 153px;
	}
	.noDataTips{
		font-size: 16px;
		color: #333;
		margin-top: 25px;
	}
	.cBlue a{
		color: #4d6ef2;
	}
	.cBlue a:hover{
		color: #1d48d2;
	}
	.courseList{
		padding: 0 35px;
	}
	.courseItem{
		width: 100%;
		height: 115px;
		padding: 20px 0;
		border-bottom: 1px solid #E2E2E2;
		line-height: 75px;
		font-size: 0;
		position: relative;
		padding-right: 118px;
	}
	.courseItem:last-child{
		border-bottom: none;
	}
	.courseImg{
		width: 110px;
		height: 75px;
		margin-right: 10px;
		border-radius: 3px;
		vertical-align: middle;
	}
	.courseName{
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		color: #333;
	}
	.courseBtn{
		width: 90px;
		height: 30px;
		border: 1px solid #e2e2e2;
		text-align: center;
		line-height: 30px;
		cursor: pointer;
		border-radius: 3px;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -15px;
		font-size: 14px;
	}
	.courseBtn:hover{
		border: 1px solid #2B54A9;
		color: #2B54A9;
	}
	.AdoptBtn{
		width: 90px;
		height: 30px;
		border: 1px solid #e2e2e2;
		text-align: center;
		line-height: 30px;
		cursor: default;
		background: #EEE;
		border-radius: 3px;
		color: #aca8a8;
		position: absolute;
		right: 0;
		top: 50%;
		margin-top: -15px;
		font-size: 14px;
	}
	.coursePaginat{
		text-align: center;
		margin-top: 20px;
	}
</style>
