import { render, staticRenderFns } from "./MockExam.vue?vue&type=template&id=ee6cc4ae&scoped=true"
import script from "./MockExam.vue?vue&type=script&lang=js"
export * from "./MockExam.vue?vue&type=script&lang=js"
import style0 from "./MockExam.vue?vue&type=style&index=0&id=ee6cc4ae&prod&scoped=scoped&lang=css"
import style1 from "./MockExam.vue?vue&type=style&index=1&id=ee6cc4ae&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee6cc4ae",
  null
  
)

export default component.exports