<template>
	<div class="MockExam">
		<div class="certificateHeader LC_Header">
			模拟考试（课程名称：{{data.cou_name}}）
			<span class="goBack" @click="goBack"><i class="el-icon-back"></i>返回</span>
		</div>
		<div class="subjectInfo">
			<div class="timeTitlePath">
				<img class="clockIcon" src="@/views/images/clockIcon.png" />
				<span class="timeVal">{{timeVal}}</span>
				<span class="stopTimeBtn" @click="pauseAnswer">暂停答题</span>
			</div>
			<div class="subjectHeader">
				<div class="subjectType">{{cuurSubject.type_text}}</div>
				<div class="subjectIndex">
					<span>第</span>
					<span class="indexColor">{{cuurIndex}}</span>
					<span>/{{subjectData.length}}</span>
				</div>
				<span v-html="cuurSubject.name_text"></span>
				<!-- <div class="subjectTitle">{{cuurSubject.name_text}}</div> -->
			</div>
			<div class="optionList">
				<ul v-if="cuurSubject.type_text == '多选题'">
					<li v-if="cuurSubject.que_optiona" class="multipleItem" @click="clickMultiple('A')" :class="{cuurChose:ArrayIndexOf('A',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>A</span>
						<span class="optiontit">{{cuurSubject.que_optiona}}</span>
					</li>
					<li v-if="cuurSubject.que_optionb" class="multipleItem" @click="clickMultiple('B')" :class="{cuurChose:ArrayIndexOf('B',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>B</span>
						<span class="optiontit">{{cuurSubject.que_optionb}}</span>
					</li>
					<li v-if="cuurSubject.que_optionc" class="multipleItem" @click="clickMultiple('C')" :class="{cuurChose:ArrayIndexOf('C',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>C</span>
						<span class="optiontit">{{cuurSubject.que_optionc}}</span>
					</li>
					<li v-if="cuurSubject.que_optiond" class="multipleItem" @click="clickMultiple('D')" :class="{cuurChose:ArrayIndexOf('D',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>D</span>
						<span class="optiontit">{{cuurSubject.que_optiond}}</span>
					</li>
					<li v-if="cuurSubject.que_optione" class="multipleItem" @click="clickMultiple('E')" :class="{cuurChose:ArrayIndexOf('E',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>E</span>
						<span class="optiontit">{{cuurSubject.que_optione}}</span>
					</li>
					<li v-if="cuurSubject.que_optionf" class="multipleItem" @click="clickMultiple('F')" :class="{cuurChose:ArrayIndexOf('F',cuurChose)>-1}">
						<div class="circular"><span class="csolid"></span></div>
						<span>F</span>
						<span class="optiontit">{{cuurSubject.que_optionf}}</span>
					</li>
				</ul>
				<ul v-else>
					<li v-if="cuurSubject.que_optiona" class="optionItem" @click="clickOption('A')" :class="{checked:checked == 'A'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>A</span>
						<span class="optiontit">{{cuurSubject.que_optiona}}</span>
					</li>
					<li v-if="cuurSubject.que_optionb" class="optionItem" @click="clickOption('B')" :class="{checked:checked == 'B'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>B</span>
						<span class="optiontit">{{cuurSubject.que_optionb}}</span>
					</li>
					<li v-if="cuurSubject.que_optionc" class="optionItem" @click="clickOption('C')" :class="{checked:checked == 'C'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>C</span>
						<span class="optiontit">{{cuurSubject.que_optionc}}</span>
					</li>
					<li v-if="cuurSubject.que_optiond" class="optionItem" @click="clickOption('D')" :class="{checked:checked == 'D'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>D</span>
						<span class="optiontit">{{cuurSubject.que_optiond}}</span>
					</li>
					<li v-if="cuurSubject.que_optione" class="optionItem" @click="clickOption('E')" :class="{checked:checked == 'E'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>E</span>
						<span class="optiontit">{{cuurSubject.que_optione}}</span>
					</li>
					<li v-if="cuurSubject.que_optionf" class="optionItem" @click="clickOption('F')" :class="{checked:checked == 'F'}">
						<div class="circular"><span class="csolid"></span></div>
						<span>F</span>
						<span class="optiontit">{{cuurSubject.que_optionf}}</span>
					</li>
				</ul>
				<div class="answerList">
					<div v-if="cuurSubject.isAnswer">
						<div class="userAnswerPath">已选答案： {{cuurSubject.choseAnswer}}</div>
					</div>
				</div>
			</div>
			<div class="stepBtnPath">
				<span class="btnList" :class="{noclick:cuurIndex == 1}" @click="prevSubject">上一题</span>
				<span class="btnList" :class="{noclick:cuurIndex == subjectData.length}" @click="nextSubject">下一题</span>
				<span class="showThis" @click="isShowClick">{{showThisText}}</span>
				<span class="submissionMockExam" @click="handPapers">交卷</span>
			</div>
			<div class="questionsNumberPath" v-if="isShow">
				<ul>
					<li class="numItem" v-for="(item,index) in subjectData" :key="index" 
					:class="[{numChecked:item.choseAnswer},{numNow: (index+1) == cuurIndex}]" @click="clicknumItem(index)">
						{{index + 1}}
					</li>
				</ul>
			</div>
		</div>
		<!-- 成绩弹窗 -->
		<el-dialog :visible.sync="scoreVisible" 
			width="350px" 
			custom-class="scoreDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:before-close="closeScore">
			<div class="scorebox">
				<div class="scoreTit">交卷成功</div>
				<img class="scoreImg" src="@/views/images/success_icon.png" />
				<div class="scoreInfo">
					<div class="scoreInfo_item">
						<p class="scoreInfo_item_num">{{score}}</p>
						<p>得分</p>
					</div>
					<div class="scoreInfo_item">
						<p class="scoreInfo_item_num">{{totalSeconds(yongshi)}}</p>
						<p>用时</p>
					</div>
					<div class="scoreInfo_item">
						<p class="scoreInfo_item_num">{{dadui + dacuo}}/{{subjectData.length}}</p>
						<p>答题情况</p>
					</div>
				</div>
				<div class="scoreBtnList">
					<div class="scoreBtn" @click="onceMore">再来一次</div>
					<div class="scoreBtn" @click="answerSheet">查看答卷</div>
				</div>
			</div>
		</el-dialog>
		<!-- 暂停考试 -->
		<el-dialog :visible.sync="suspendVisible"
			width="300px" 
			custom-class="suspendDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<div class="suspendbox">
				<div class="suspendTit">考试已暂停</div>
				<div class="suspendBtn" @click="goOnExam">继续考试</div>
			</div>
		</el-dialog>
		<!-- 答卷弹窗 -->
		<el-dialog :visible.sync="answerVisible"
			width="900px" 
			custom-class="answerDialog"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			:show-close="false">
			<div class="answer_box">
				<div class="answerHeader">模拟考试（课程名称：{{data.cou_name}}）答卷</div>
				<div class="answerInfo">
					<div class="answerLeft">
						<div class="answerScore_box">
							<p class="answerScore">{{score}}</p>
							<p class="answerTitle">得分</p>
						</div>
					</div>
					<div class="answerRight">
						<div>
							<p>总用时</p>
							<p>答对（题）</p>
							<p>答错（题）</p>
							<p>未答（题）</p>
						</div>
						<div>
							<p class="totalTime">{{totalSeconds(yongshi)}}</p>
							<p class="dadui">{{dadui}}</p>
							<p class="dacuo">{{dacuo}}</p>
							<p class="weida">{{weida}}</p>
						</div>
					</div>
				</div>
				<div class="answerSubject" ref="answerSubject">
					<div class="answerSubject_item" v-for="(item,index) in subjectData" :key="index" :ref="item.que_id">
						<div class="subjectHeader">
							<div class="subjectType">{{item.type_text}}</div>
							<div class="subjectIndex">
								<span>第</span>
								<span class="indexColor">{{index + 1}}</span>
								<span>/{{subjectData.length}}</span>
							</div>
							{{item.name_text}}
							<!--<div class="subjectTitle">{{item.name_text}}</div>-->
						</div>
						<div class="optionList">
							<ul v-if="item.type_text == '多选题'">
								<li v-if="item.que_optiona" class="multipleItem" :class="multiplebindClass('A',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>A</span>
									<span class="optiontit">{{item.que_optiona}}</span>
								</li>
								<li v-if="item.que_optionb" class="multipleItem" :class="multiplebindClass('B',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>B</span>
									<span class="optiontit">{{item.que_optionb}}</span>
								</li>
								<li v-if="item.que_optionc" class="multipleItem" :class="multiplebindClass('C',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>C</span>
									<span class="optiontit">{{item.que_optionc}}</span>
								</li>
								<li v-if="item.que_optiond" class="multipleItem" :class="multiplebindClass('D',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>D</span>
									<span class="optiontit">{{item.que_optiond}}</span>
								</li>
								<li v-if="item.que_optione" class="multipleItem" :class="multiplebindClass('E',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>E</span>
									<span class="optiontit">{{item.que_optione}}</span>
								</li>
								<li v-if="item.que_optionf" class="multipleItem" :class="multiplebindClass('F',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>F</span>
									<span class="optiontit">{{item.que_optionf}}</span>
								</li>
							</ul>
							<ul v-else>
								<li v-if="item.que_optiona" class="optionItem" :class="bindClass('A',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>A</span>
									<span class="optiontit">{{item.que_optiona}}</span>
								</li>
								<li v-if="item.que_optionb" class="optionItem" :class="bindClass('B',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>B</span>
									<span class="optiontit">{{item.que_optionb}}</span>
								</li>
								<li v-if="item.que_optionc" class="optionItem" :class="bindClass('C',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>C</span>
									<span class="optiontit">{{item.que_optionc}}</span>
								</li>
								<li v-if="item.que_optiond" class="optionItem" :class="bindClass('D',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>D</span>
									<span class="optiontit">{{item.que_optiond}}</span>
								</li>
								<li v-if="item.que_optione" class="optionItem" :class="bindClass('E',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>E</span>
									<span class="optiontit">{{item.que_optione}}</span>
								</li>
								<li v-if="item.que_optionf" class="optionItem" :class="bindClass('F',item)">
									<div class="circular"><span class="csolid"></span></div>
									<span>F</span>
									<span class="optiontit">{{item.que_optionf}}</span>
								</li>
							</ul>
						</div>
						<div class="answerList">
							<div class="userAnswerPath">您的答案：{{item.choseAnswer}}</span></div>
							<div class="rightAnswerPath">正确答案：{{PublicJs.Decrypt(item.que_answer)}}</div>
						</div>
					</div>
				</div>
				<div class="answerNoList">
					<ul>
						<li class="answerNoItem" v-for="(item,index) in subjectData" :key="index" 
						:class="[{numCorrect:PublicJs.Decrypt(item.que_answer) == item.choseAnswer},
						{numError: item.choseAnswer && (PublicJs.Decrypt(item.que_answer) != item.choseAnswer)}]" 
						@click="clickanswerNo(item.que_id)">
							{{index + 1}}
						</li>
					</ul>
					<div class="legend">
						<p><span class="daduiLen legendIcon"></span><span class="legendText">正确</span></p>
						<p><span class="dacuoLen legendIcon"></span><span class="legendText">错误</span></p>
						<p><span class="weidaLen legendIcon"></span><span class="legendText">未答</span></p>
					</div>
					<div class="gotoList" @click="gobackList">返回列表</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import $ from 'jquery'
	export default {
		props:{
			data:{
				type: Object
			},
			titleSync: String,
		},
		data() {
			return {
				subjectData:[],//题目数据
				cuurSubject:{},//当前题目
				cuurIndex:1,//当前题号
				exac_exam_time:0,//考试时长
				socres:{},//分数
				timeVal:"",//倒计时
				cuurChose:[],//多选选中
				checked:"",//单选选中
				isShow:false,//是否显示答题卡
				timeId:null,//倒计时
				scoreVisible:false,//成绩弹窗
				score:0,//分数
				suspendVisible:false,//暂停考试弹窗
				answerVisible:false,//答卷弹窗
				dadui:0,//答对
				dacuo:0,//答错
				weida:0,//未答
				yongshi:0,//用时
				showThisText:"显示答题卡"
			}
		},
		methods:{
			//获取模拟考试题目
			getmockExam(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/mockExam.html", {
					token:window.sessionStorage.getItem('token'),
					course_id:this.PublicJs.Encrypt(this.data.sig_course_id)
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 0){
						this.cuurIndex = 1;
						this.subjectData = response.data.data;
						this.exac_exam_time = response.data.exac_exam_time * 60 * 1000;
						this.socres = response.data.socres;
						var subjectData = this.subjectData;
						this.subjectData = []
						for(var i=0;i<subjectData.length;i++){
							if(subjectData[i]){
								this.subjectData.push(subjectData[i])
							}
						}
						for(var i = 0;i<this.subjectData.length;i++){
							this.subjectData[i].isAnswer = false;
							this.subjectData[i].choseAnswer = ""
						}
						this.cuurSubject = this.subjectData[0];
						//倒计时
						this.TimeDown();
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击选项(单选)
			clickOption(val){
				this.checked = val
				this.cuurSubject.isAnswer = true;
				this.cuurSubject.choseAnswer = val;
				this.subjectData[this.cuurIndex-1].isAnswer = true;
				this.subjectData[this.cuurIndex-1].choseAnswer = val
			},
			//上一题
			prevSubject(){
				if(this.cuurSubject.type_text == "多选题"){
					//多选确定
					this.MultipleOk()
				}
				this.checked = "";
				this.cuurChose = [];
				if(this.cuurIndex > 1){
					this.cuurSubject = this.subjectData[this.cuurIndex -2];
					this.cuurIndex = this.cuurIndex - 1;
				}
				//默认选中
				this.defaultSubjectInfo()
			},
			//下一题
			nextSubject(){
				if(this.cuurSubject.type_text == "多选题"){
					//多选确定
					this.MultipleOk()
				}
				this.checked = "";
				this.cuurChose = [];
				if(this.cuurIndex < this.subjectData.length){
					this.cuurSubject = this.subjectData[this.cuurIndex];
					this.cuurIndex = this.cuurIndex + 1;
				}
				//默认选中
				this.defaultSubjectInfo()
			},
			//点击显示隐藏答题卡
			isShowClick(){
				this.isShow = !this.isShow;
				if(this.isShow){
					this.showThisText = "隐藏答题卡"
				}else{
					this.showThisText = "显示答题卡"
				}
			},
			//点击题号
			clicknumItem(val){
				if(this.cuurSubject.type_text == "多选题"){
					//多选确定
					this.MultipleOk()
				}
				this.checked = "";
				this.cuurChose = [];
				this.cuurSubject = this.subjectData[val];
				this.cuurIndex = val + 1;
				//默认选中
				this.defaultSubjectInfo()
			},
			//默认选中
			defaultSubjectInfo(){
				if(this.cuurSubject.isAnswer){
					if(this.cuurSubject.type_text == "多选题"){
						this.cuurChose = this.cuurSubject.choseAnswer.split(",");
					}else{
						this.checked = this.cuurSubject.choseAnswer;
					}
				}
			},
			//是否在数组中存在
			ArrayIndexOf(val,arr){
				for (var i = 0; i < arr.length; i++) {
					if (arr[i] == val) return i;
				}
				return -1;
			},
			//在数组中删除
			ArrayRemove(val,arr) {
				var index = arr.indexOf(val);
				if (index > -1) {
					arr.splice(index, 1);
				}	
			},
			//点击多选选项
			clickMultiple(val){
				if(this.ArrayIndexOf(val,this.cuurChose) > -1){
					this.ArrayRemove(val,this.cuurChose) 
				}else{
					this.cuurChose.push(val);
				}
				//多选确定
				this.MultipleOk()
			},
			//多选确定
			MultipleOk(){
				if(this.cuurChose.length){
					var optionArr = ["A","B","C","D","E","F"];
					var finalArr = []
					for(var i=0;i<optionArr.length;i++){
						for(var j=0;j<this.cuurChose.length;j++){
							if(optionArr[i] == this.cuurChose[j]){
								finalArr.push(optionArr[i])
							}
						}
					}
					this.cuurSubject.isAnswer = true;
					this.cuurSubject.choseAnswer = finalArr.join(",");
					this.subjectData[this.cuurIndex-1].isAnswer = true;
					this.subjectData[this.cuurIndex-1].choseAnswer = finalArr.join(",");
				}				
			},
			//倒计时
			TimeDown() {
				var that = this;
				//倒计时的总秒数
				var totalSeconds = parseInt(this.exac_exam_time / 1000);
				//取模（余数）
				var modulo = totalSeconds % (60 * 60 * 24);
				//小时数
				var hours = Math.floor(modulo / (60 * 60));
				modulo = modulo % (60 * 60);
				//分钟
				var minutes = Math.floor(modulo / 60);
				//秒
				var seconds = modulo % 60;
				hours = hours.toString().length == 1 ? '0' + hours : hours;
				minutes = minutes.toString().length == 1 ? '0' + minutes : minutes;
				seconds = seconds.toString().length == 1 ? '0' + seconds : seconds;
				//输出到页面
				this.timeVal =  hours + ":" + minutes + ":" + seconds;
				//延迟一秒执行自己
				if (hours == "00" && minutes == "00" && parseInt(seconds) - 1 < 0) {
					//显示成绩
					this.handPapers()
				} else {
					this.timeId = setTimeout(function () {
						that.yongshi ++ ;
						that.exac_exam_time = that.exac_exam_time - 1000;
						that.TimeDown();
					}, 1000)
				}
			},
			//显示用时
			totalSeconds(val){
				//取模（余数）
				var modulo = val % (60 * 60 * 24);
				//小时数
				var hours = Math.floor(modulo / (60 * 60));
				modulo = modulo % (60 * 60);
				//分钟
				var minutes = Math.floor(modulo / 60);
				//秒
				var seconds = modulo % 60;
				hours = hours.toString().length == 1 ? '0' + hours : hours;
				minutes = minutes.toString().length == 1 ? '0' + minutes : minutes;
				seconds = seconds.toString().length == 1 ? '0' + seconds : seconds;
				//输出到页面
				return  hours + ":" + minutes + ":" + seconds;
			},
			//显示成绩
			handPapers(){
				clearTimeout(this.timeId)
				this.timeId = null;
				this.scoreVisible = true;
				for(var i=0;i<this.subjectData.length;i++){
					if(this.subjectData[i].isAnswer){
						if(this.subjectData[i].choseAnswer == this.PublicJs.Decrypt(this.subjectData[i].que_answer)){
							this.score = this.score + Number(this.socres[this.subjectData[i].que_type])
							this.dadui ++ ;
						}else{
							this.dacuo ++ ;
						}
					}else{
						this.weida ++;
					}
				}
			},
			//关闭按钮
			closeScore(done){
				this.$emit('update:titleSync', "list");
				done();
			},
			//再来一次
			onceMore(){
				this.scoreVisible = false;
				//获取模拟考试题目
				this.getmockExam()
			},
			//查看答卷
			answerSheet(){
				this.scoreVisible = false;
				this.answerVisible = true;
			},
			//暂停答题
			pauseAnswer(){
				clearTimeout(this.timeId)
				this.timeId = null;
				this.suspendVisible = true;
			},
			//继续考试
			goOnExam(){
				var that = this;
				this.suspendVisible = false;
				this.timeId = setTimeout(function () {
					that.exac_exam_time = that.exac_exam_time - 1000;
					that.TimeDown();
				}, 1000)
			},
			//返回
			goBack(){
				this.$emit('update:titleSync', "list");
			},
			//答卷多选绑定
			multiplebindClass(type,item){
				if(item){
					var choseAnswer = [];
					var que_answer = this.PublicJs.Decrypt(item.que_answer).split(",");
					if(item.choseAnswer){
						choseAnswer = item.choseAnswer.split(",");
					}
					var errorArr = []
					for(var i=0;i<choseAnswer.length;i++){
						if(que_answer.indexOf(choseAnswer[i]) == -1){
							errorArr.push(choseAnswer[i])
						}
					}
					var className = "";
					if(this.ArrayIndexOf(type,que_answer)>-1){
						className += "correctM"
					}
					if(this.ArrayIndexOf(type,errorArr)>-1){
						className += "errorM"
					}
					return className
				}
			},
			//答卷单选绑定
			bindClass(type,item){
				if(item){
					var choseAnswer = item.choseAnswer;
					var que_answer = this.PublicJs.Decrypt(item.que_answer);
					var errorArr = "";
					if(choseAnswer && choseAnswer!=que_answer){
						errorArr = choseAnswer
					}
					var className = "";
					if(que_answer == type){
						className += "correct"
					}
					if(errorArr == type){
						className += "error"
					}
					return className
				}
			},
			//返回列表
			gobackList(){
				this.answerVisible = false;
				this.$emit('update:titleSync', "list");
			},
			//点击跳转对应题目
			clickanswerNo(val){
				var that = this;
				var scrollTopVal = that.$refs[val][0].offsetTop - that.$refs.answerSubject.offsetTop
				$(this.$refs.answerSubject).animate({
					 scrollTop: scrollTopVal,
				}, 1000);
			}
		},
		created() {
			//获取模拟考试题目
			this.getmockExam()
		}
	}
</script>

<style scoped="scoped">
	.goBack{
		font-size: 14px;
		color: #4E6FF1;
		position: absolute;
		right: 35px;
		top: 0;
		cursor: pointer;
	}
	.goBack:hover{
		opacity: 0.8;
	}
	.subjectInfo{
		padding: 35px;
	}
	.timeTitlePath{
		width: 100%;
		height: 48px;
		border-top: 1px solid #e2e2e2;
		border-bottom: 1px solid #e2e2e2;
		position: relative;
		line-height: 48px;
		font-size: 0;
	}
	.clockIcon{
		vertical-align: middle;
	}
	.timeVal{
		position: absolute;
		left: 40px;
		top: 0px;
		font-size: 16px;
		color: #666666;
	}
	.stopTimeBtn{
		position: absolute;
		right: 0;
		top: 0;
		font-size: 14px;
		color: #666666;
		cursor: pointer;
	}
	.subjectHeader{
		font-size: 14px;
		color: #101e18;
		line-height: 25px;
		margin-top: 20px;
	}
	/* .subjectHeader::after{
		content: "";
		display: block;
		clear: both;
	} */
	.subjectType{
		display: inline-block;
		height: 25px;
		padding: 0px 7px;
		background-color: #2B54A9;
		font-size: 12px;
		color: #ffffff;
		border-radius: 3px;
		line-height: 25px;
		margin-right: 10px;
	}
	.subjectIndex{
		font-size: 12px;
		color: #999999;
		line-height: 25px;
		display: inline-block;
		margin-right: 10px;
	}
	.subjectIndex .indexColor{
		color: #ff771e;
	}
	.subjectTitle{
		font-size: 14px;
		color: #101e18;
		line-height: 25px;
		display: inline-block;
	}
	.optionList{
		padding: 21px 25px 25px;
		border-bottom: 1px solid #e2e2e2;
		position: relative;
	}
	.optionItem{
		height: 16px;
		line-height: 16px;
		margin-bottom: 19px;
		font-size: 14px;
		color: #101e18;
		cursor: pointer;
	}
	.optionItem::after,
	.answerList::after{
		content: "";
		clear: both;
		display: block;
	}
	.circular{
		width: 16px;
		height: 16px;
		border-radius: 8px;
		border: 1px solid #E2E2E2;
		float: left;
		margin-right: 10px;
		text-align: center;
		line-height: 16px;
	}
	.csolid{
		width: 12px;
		height: 12px;
		border-radius: 6px;
		display: inline-block;
	}
	.optiontit{
		margin-left: 10px;
	}
	.optionItem:hover{
		color: #2B54A9;
	}
	.checked{
		color: #5EC513;
	}
	.checked .circular{
		border: 1px solid #5EC513;
	}
	.checked .csolid{
		background-color: #5EC513;
	}
	.answerList{
		height: 20px;
		font-size: 14px;
		color: #101e18;
	}
	.okBtnStyle{
		position: absolute;
		width: 90px;
		height: 35px;
		background-color: #2B54A9;
		color: #ffffff;
		cursor: pointer;
		border-radius: 3px;
		text-align: center;
		line-height: 35px;
		font-size: 16px;
		right: 25px;
		bottom: 25px;
	}
	.okBtnStyle:hover{
		opacity: 0.8;
	}
	.stepBtnPath{
		margin-top: 20px;
		position: relative;
	}
	.btnList{
		display: inline-block;
		background-color: #2B54A9;
		color: #ffffff;
		cursor: pointer;
		width: 90px;
		height: 35px;
		border-radius: 3px;
		text-align: center;
		line-height: 35px;
		font-size: 16px;
		margin-right: 10px;
	}
	.btnList:hover{
		opacity: 0.8;
	}
	.btnList.noclick{
		background-color: #efefef;
		color: #666666;
		cursor: no-drop;
	}
	.showThis{
		float: right;
		cursor: pointer;
		font-size: 14px;
		color: #666666;
	}
	.showThis:hover{
		color: #40AFFE;
	}
	.questionsNumberPath{
		margin-top: 40px;
		max-height: 320px;
		overflow: auto;
	}
	.questionsNumberPath::after{
		content: "";
		display: block;
		clear: both;
	}
	.numItem{
		float: left;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		margin: 0px 3px 6px 0px;
		box-sizing: border-box;
		border-radius: 3px;
		font-size: 14px;
		background-color: #ffffff;
		border: 1px solid #e2e2e2;
		color: #666666;
	}
	.numItem.numChecked{
		background-color: #5ec513;
		border: 1px solid #5ec513;
		color: #ffffff;
	}
	.numItem:hover,
	.numItem.numNow{
		border: 1px solid #2B54A9;
	}
	.multipleItem{
		height: 16px;
		line-height: 16px;
		margin-bottom: 19px;
		font-size: 14px;
		color: #101e18;
		cursor: pointer;
	}
	.multipleItem .circular{
		border-radius: 3px;
	}
	.multipleItem .csolid{
		border-radius: 2px;
	}
	.multipleItem.cuurChose{
		color: #5ec513;
	}
	.multipleItem.cuurChose .circular{
		border: 1px solid #5ec513;
	}
	.multipleItem.cuurChose .csolid{
		background-color: #5ec513;
	}
	.submissionMockExam{
		display: block;
		width: 90px;
		height: 35px;
		font-size: 16px;
		text-align: center;
		line-height: 35px;
		border-radius: 3px;
		color: #ffffff;
		cursor: pointer;
		background: -webkit-linear-gradient(left, #ff9f49, #ff771e);
		background: -o-linear-gradient(right, #ff9f49, #ff771e);
		background: -moz-linear-gradient(right, #ff9f49, #ff771e);
		background: linear-gradient(to right, #ff9f49, #ff771e);
		-moz-box-shadow: 3px 3px 3px #ffdfca;
		-webkit-box-shadow: 3px 3px 3px #ffdfca;
		box-shadow: 3px 3px 3px #ffdfca;
		position: absolute;
		bottom: 0;
		left: 50%;
		margin-left: -45px;
	}
	.submissionMockExam:hover{
		background: -webkit-linear-gradient(left, #ff771e, #ff9f49);
		background: -o-linear-gradient(right, #ff771e, #ff9f49);
		background: -moz-linear-gradient(right, #ff771e, #ff9f49);
		background: linear-gradient(to right, #ff771e, #ff9f49);
	}
	.scorebox{
		width: 350px;
		height: 370px;
		background-color: #FFF;
		text-align: center;
	}
	.scoreTit{
		font-size: 16px;
		color: #333;
		line-height: 68px;
	}
	.scoreImg{
		width: 70px;
		height: 70px;
	}
	.scoreInfo::after{
		content: "";
		display: block;
		clear: both;
	}
	.scoreInfo_item{
		width: 33.33%;
		float: left;
		font-size: 15px;
		color: #333;
		font-weight: lighter;
		line-height: 30px;
		padding: 35px 0;
	}
	.scoreBtn{
		display: inline-block;
		width: 120px;
		height: 44px;
		border-radius: 22px;
		line-height: 44px;
		color: #fff;
		background-color: #4C6DF1;
		font-size: 14px;
		margin: 0 10px;
		cursor: pointer;
	} 
	.scoreBtn:hover{
		opacity: 0.8;
	}
	.scoreInfo_item_num{
		font-size: 18px;
		font-weight: bold;
	}
	.suspendbox{
		height: 150px;
	}
	.suspendTit{
		text-align: center;
		line-height: 90px;
		color: #1094fa;
		font-size: 24px;
	}
	.suspendBtn{
		display: block;
		width: 90px;
		height: 35px;
		margin: 10px auto 0px;
		text-align: center;
		line-height: 35px;
		border-radius: 3px;
		background-color: #1E9FFF;
		cursor: pointer;
		font-size: 14px;
		color: #ffffff;
	}
	.suspendBtn:hover{
		opacity: 0.8;
	}
	.answer_box{
		background-color: #F5F7F9;
		padding: 10px;
		position: relative;
	}
	.answer_box::after{
		content: "";
		clear: both;
		display: block;
	}
	.answerHeader{
		width: 100%;
		height: 40px;
		background-color: #FFF;
		line-height: 40px;
		padding-left: 10px;
	}
	.answerInfo{
		width: 100%;
		height: 90px;
		margin-top: 10px;
		background-color: #FFF;
		margin-bottom: 10px;
	}
	.answerInfo::after{
		content: "";
		display: block;
		clear: both;
	}
	.answerLeft{
		width: 200px;
		height: 100%;
		float: left;
	}
	.answerScore_box{
		width: 80px;
		height: 80px;
		border-radius: 40px;
		background-color: #3487F9;
		margin: 5px auto;
		text-align: center;
		color: #FFF;
		padding-top: 13px;
	}
	.answerScore{
		font-size: 30px;
		font-weight: bold;
		line-height: 30px;
		margin-bottom: 7px;
	}
	.answerTitle{
		font-size: 14px;
		line-height: 14px;
	}
	.answerRight{
		width: 680px;
		float: left;
	}
	.answerRight div{
		width: 100%;
		height: 45px;
		line-height: 45px;
		text-align: center;
		font-size: 0;
	}
	.answerRight div:first-child{
		border-bottom: 1px solid #E2E2E2;
	}
	.answerRight div p{
		display: inline-block;
		font-size: 14px;
		width: 170px;
		border-left: 1px solid #E2E2E2;
	}
	.totalTime{
		color: #44A4F6;
		font-weight: bold;
	}
	.dadui{
		color: #5EC513;
		font-weight: bold;
	}
	.dacuo{
		color: #EA3410;
		font-weight: bold;
	}
	.weida{
		color: #C1C1C1;
		font-weight: bold;
	}
	.answerSubject{
		width: 610px;
		float: left;
		background-color: #FFF;
		height: 500px;
		overflow: auto;
	}
	.answerNoList{
		width: 260px;
		float: right;
		background-color: #FFF;
	}
	.answerNoList ul{
		padding: 10px 0 0 10px;
		overflow: auto;
		height: 226px;
	}
	.answerNoList ul::after{
		content: "";
		clear: both;
		display: block;
	}
	.answerNoItem{
		float: left;
		width: 30px;
		height: 30px;
		text-align: center;
		line-height: 28px;
		cursor: pointer;
		margin: 0px 3px 6px 0px;
		box-sizing: border-box;
		border-radius: 3px;
		font-size: 12px;
		background-color: #ffffff;
		border: 1px solid #e2e2e2;
		color: #666666;
	}
	.answerNoItem.numError{
		background-color: #EA3410;
		border: 1px solid #EA3410;
		color: #FFF;
	}
	.answerNoItem.numCorrect{
		background-color: #5EC513;
		border: 1px solid #5EC513;
		color: #FFF;
	}
	.legend{
		width: 240px;
		border-top: 1px dashed #E2E2E2;
		margin: 0 auto;
		margin-top: 10px;
		padding: 10px;
	}
	.legend p{
		display: inline-block;
		font-size: 0;
		line-height: 30px;
		margin-right: 20px;
	}
	.legend p span.legendIcon{
		display: inline-block;
		width: 14px;
		height: 14px;
		border-radius: 3px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.legend p span.legendText{
		vertical-align: middle;
		font-size: 14px;
	}
	.legend p span.daduiLen{
		background-color: #5EC513;
	}
	.legend p span.dacuoLen{
		background-color: #EA3410;
	}
	.legend p span.weidaLen{
		border: 1px solid #e2e2e2;
	}
	.gotoList{
		width: 220px;
		height: 36px;
		text-align: center;
		line-height: 36px;
		margin: 10px auto 20px;
		background-color: #4F6FF1;
		color: #FFF;
		border-radius: 5px;
		cursor: pointer;
	}
	.gotoList:hover{
		opacity: 0.8;
	}
	.answerSubject_item{
		border-bottom: 1px solid #e2e2e2;
	}
	.answerSubject_item .subjectHeader{
		padding-left: 10px;
	}
	.answerSubject_item .optionList{
		border-bottom:0;
		padding-bottom: 0;
	}
	.answerSubject_item .answerList{
		height: 40px;
		line-height: 40px;
		background-color: #F8F8F8;
		padding: 0 10px;
		border-top: 1px dashed #e2e2e2;
	}
	.answerSubject_item .answerList div{
		display: inline-block;
		margin-right: 40px;
		font-size: 12px;
	}
	.optionItem.correct,
	.multipleItem.correctM{
		color: #5EC513;
	}
	.optionItem.correct .circular,
	.multipleItem.correctM .circular{
		border: 1px solid #5EC513;
	}
	.optionItem.correct .csolid,
	.multipleItem.correctM .csolid{
		background-color: #5EC513;
	}
	.optionItem.error,
	.multipleItem.errorM{
		color: #EA3410;
	}
	.optionItem.error .circular,
	.multipleItem.errorM .circular{
		border: 1px solid #EA3410;
	}
	.optionItem.error .csolid,
	.multipleItem.errorM .csolid{
		background-color: #EA3410;
	}
</style>
<style>
	.scoreDialog .el-dialog__header,
	.scoreDialog .el-dialog__body,
	.suspendDialog .el-dialog__header,
	.suspendDialog .el-dialog__body,
	.answerDialog .el-dialog__header,
	.answerDialog .el-dialog__body{
		padding: 0;
	}
	.scoreDialog .el-dialog__headerbtn{
		width: 30px;
		height: 30px;
		background-color: #777777;
		border-radius: 15px;
		border: 3px solid #FFF;
		top: -13px;
		right: -12px;
		text-align: center;
		line-height: 36px;
		font-size: 0;
	}
	.scoreDialog .el-dialog__headerbtn .el-dialog__close{
		color: #FFF;
		font-weight: bold;
		font-size: 18px;
	}
</style>
