<template>
	<div class="practicePapers">
		<div class="certificateHeader LC_Header">
			练习模式（课程名称：{{data.cou_name}}）
			<span class="goBack" @click="goBack"><i class="el-icon-back"></i>返回</span>
		</div>
		<div class="subjectInfo">
			<div class="subjectHeader">
				<div class="subjectType">{{cuurSubject.type_text}}</div>
				<div class="subjectIndex">
					<span>第</span>
					<span class="indexColor">{{cuurIndex}}</span>
					<span>/{{subjectData.length}}</span>
				</div>
				<span v-html="cuurSubject.name_text"></span>
				<!-- <div class="subjectTitle">{{cuurSubject.name_text}}</div> -->
			</div>
			<div class="optionList">
				<ul v-if="cuurSubject.type_text == '多选题'">
					<li v-if="cuurSubject.que_optiona" class="multipleItem" @click="clickMultiple('A')" :class="[{correctM:singlecorrect.indexOf('A')>-1},{errorM:singleerror.indexOf('A')>-1},{cuurChose:ArrayIndexOf('A',cuurChose)>-1}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>A</span>
						<span class="optiontit">{{cuurSubject.que_optiona}}</span>
					</li>
					<li v-if="cuurSubject.que_optionb" class="multipleItem" @click="clickMultiple('B')" :class="[{correctM:singlecorrect.indexOf('B')>-1},{errorM:singleerror.indexOf('B')>-1},{cuurChose:ArrayIndexOf('B',cuurChose)>-1}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>B</span>
						<span class="optiontit">{{cuurSubject.que_optionb}}</span>
					</li>
					<li v-if="cuurSubject.que_optionc" class="multipleItem" @click="clickMultiple('C')" :class="[{correctM:singlecorrect.indexOf('C')>-1},{errorM:singleerror.indexOf('C')>-1},{cuurChose:ArrayIndexOf('C',cuurChose)>-1}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>C</span>
						<span class="optiontit">{{cuurSubject.que_optionc}}</span>
					</li>
					<li v-if="cuurSubject.que_optiond" class="multipleItem" @click="clickMultiple('D')" :class="[{correctM:singlecorrect.indexOf('D')>-1},{errorM:singleerror.indexOf('D')>-1},{cuurChose:ArrayIndexOf('D',cuurChose)>-1}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>D</span>
						<span class="optiontit">{{cuurSubject.que_optiond}}</span>
					</li>
					<li v-if="cuurSubject.que_optione" class="multipleItem" @click="clickMultiple('E')" :class="[{correctM:singlecorrect.indexOf('E')>-1},{errorM:singleerror.indexOf('E')>-1},{cuurChose:ArrayIndexOf('E',cuurChose)>-1}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>E</span>
						<span class="optiontit">{{cuurSubject.que_optione}}</span>
					</li>
					<li v-if="cuurSubject.que_optionf" class="multipleItem" @click="clickMultiple('F')" :class="[{correctM:singlecorrect.indexOf('F')>-1},{errorM:singleerror.indexOf('F')>-1},{cuurChose:ArrayIndexOf('F',cuurChose)>-1}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>F</span>
						<span class="optiontit">{{cuurSubject.que_optionf}}</span>
					</li>
				</ul>
				<ul v-else>
					<li v-if="cuurSubject.que_optiona" class="optionItem" @click="clickOption('A')" :class="[{correct:singlecorrect == 'A'},{error:singleerror == 'A'}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>A</span>
						<span class="optiontit">{{cuurSubject.que_optiona}}</span>
					</li>
					<li v-if="cuurSubject.que_optionb" class="optionItem" @click="clickOption('B')" :class="[{correct:singlecorrect == 'B'},{error:singleerror == 'B'}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>B</span>
						<span class="optiontit">{{cuurSubject.que_optionb}}</span>
					</li>
					<li v-if="cuurSubject.que_optionc" class="optionItem" @click="clickOption('C')" :class="[{correct:singlecorrect == 'C'},{error:singleerror == 'C'}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>C</span>
						<span class="optiontit">{{cuurSubject.que_optionc}}</span>
					</li>
					<li v-if="cuurSubject.que_optiond" class="optionItem" @click="clickOption('D')" :class="[{correct:singlecorrect == 'D'},{error:singleerror == 'D'}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>D</span>
						<span class="optiontit">{{cuurSubject.que_optiond}}</span>
					</li>
					<li v-if="cuurSubject.que_optione" class="optionItem" @click="clickOption('E')" :class="[{correct:singlecorrect == 'E'},{error:singleerror == 'E'}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>E</span>
						<span class="optiontit">{{cuurSubject.que_optione}}</span>
					</li>
					<li v-if="cuurSubject.que_optionf" class="optionItem" @click="clickOption('F')" :class="[{correct:singlecorrect == 'F'},{error:singleerror == 'F'}]">
						<div class="circular"><span class="csolid"></span></div>
						<span>F</span>
						<span class="optiontit">{{cuurSubject.que_optionf}}</span>
					</li>
				</ul>
				<div class="answerList">
					<div v-if="cuurSubject.isAnswer">
						<div class="userAnswerPath">已选答案：<span :class="cuurSubject.choseAnswer==singlecorrect ? 'correctA' : 'errorA'">{{cuurSubject.choseAnswer}}</span></div>
						<div class="rightAnswerPath">正确答案：<span>{{singlecorrect}}</span></div>
					</div>
				</div>
				<div v-if="cuurSubject.type_text == '多选题' && !this.cuurSubject.isAnswer" class="okBtnStyle" @click="MultipleOk">确定</div>
			</div>
			<div class="stepBtnPath">
				<span class="btnList" :class="{noclick:cuurIndex == 1}" @click="prevSubject">上一题</span>
				<span class="btnList" :class="{noclick:cuurIndex == subjectData.length}" @click="nextSubject">下一题</span>
				<span class="showThis" @click="isShowClick">{{showThisText}}</span>
			</div>
			<div class="questionsNumberPath" v-if="isShow">
				<ul>
					<li class="numItem" v-for="(item,index) in subjectData" :key="index" 
					:class="[{numCorrect:PublicJs.Decrypt(item.que_answer) == item.choseAnswer},
					{numError: item.choseAnswer && (PublicJs.Decrypt(item.que_answer) != item.choseAnswer)},
					{numNow: (index+1) == cuurIndex}]" @click="clicknumItem(index)">
					{{index + 1}}
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			data:{
				type: Object
			},
			titleSync: String,
		},
		data() {
			return {
				subjectData:[],//题目数据
				cuurSubject:{},//当前题目
				cuurIndex:1,//当前题号
				singlecorrect:"",//单选正确答案
				singleerror:"",//单选选择答案是否错误
				isShow:false,//是否显示答题卡
				cuurChose:[],//多选当前选中
				showThisText:"显示答题卡",
			}
		},
		methods:{
			//获取练习题目
			getExerciseData(){
				this.$http.post(this.PublicJs.publicPath + "/api/student.Info/exerciseData.html", {
					token:window.sessionStorage.getItem('token'),
					course_id:this.PublicJs.Encrypt(this.data.sig_course_id)
				}, {
					emulateJSON: true
				}).then(response => {
					if(response.data.code == 1){
						this.subjectData = response.data.data.data;
						var subjectData = this.subjectData;
						this.subjectData = []
						for(var i=0;i<subjectData.length;i++){
							if(subjectData[i]){
								this.subjectData.push(subjectData[i])
							}
						}
						for(var i = 0;i<this.subjectData.length;i++){
							this.subjectData[i].isAnswer = false;
							this.subjectData[i].choseAnswer = ""
						}
						this.cuurSubject = this.subjectData[0];
						// console.log(this.subjectData)
					}else{
						this.$message({
							message: response.data.msg,
							type: 'warning',
							showClose: true
						});
					}
				}, response => {
					if(response.status == 401){
						var that = this;
						this.$message({
							message: "您的账号在别处登录，请重新登录",
							type: 'warning',
							showClose: true
						});
						window.sessionStorage.removeItem('user_id')
						window.sessionStorage.removeItem('token')
						window.sessionStorage.removeItem('userinfo')
						setTimeout(function(){
							that.$router.go(0)
						},2000); 
						
					}
				});
			},
			//点击选项
			clickOption(val){
				if(!this.cuurSubject.isAnswer){
					this.singlecorrect = this.PublicJs.Decrypt(this.cuurSubject.que_answer)
					if(this.singlecorrect != val){
						this.singleerror = val
					}
					this.cuurSubject.isAnswer = true;
					this.cuurSubject.choseAnswer = val;
					this.subjectData[this.cuurIndex-1].isAnswer = true;
					this.subjectData[this.cuurIndex-1].choseAnswer = val
				}
			},
			//点击显示隐藏答题卡
			isShowClick(){
				this.isShow = !this.isShow;
				if(this.isShow){
					this.showThisText = "隐藏答题卡"
				}else{
					this.showThisText = "显示答题卡"
				}
			},
			//上一题
			prevSubject(){
				this.singlecorrect = "";
				this.singleerror = "";
				this.cuurChose = [];
				if(this.cuurIndex > 1){
					this.cuurSubject = this.subjectData[this.cuurIndex -2];
					this.cuurIndex = this.cuurIndex - 1;
				}
				//默认选中
				this.defaultSubjectInfo()
			},
			//下一题
			nextSubject(){
				this.singlecorrect = "";
				this.singleerror = "";
				this.cuurChose = [];
				if(this.cuurIndex < this.subjectData.length){
					this.cuurSubject = this.subjectData[this.cuurIndex];
					this.cuurIndex = this.cuurIndex + 1;
				}
				//默认选中
				this.defaultSubjectInfo()
			},
			//点击题号
			clicknumItem(val){
				this.singlecorrect = "";
				this.singleerror = "";
				this.cuurChose = [];
				this.cuurSubject = this.subjectData[val];
				this.cuurIndex = val + 1;
				//默认选中
				this.defaultSubjectInfo()
			},
			//点击多选选项
			clickMultiple(val){
				if(!this.cuurSubject.isAnswer){
					if(this.ArrayIndexOf(val,this.cuurChose) > -1){
						this.ArrayRemove(val,this.cuurChose) 
					}else{
						this.cuurChose.push(val);
					}
				}
			},
			//是否在数组中存在
			ArrayIndexOf(val,arr){
				for (var i = 0; i < arr.length; i++) {
					if (arr[i] == val) return i;
				}
				return -1;
			},
			//在数组中删除
			ArrayRemove(val,arr) {
				var index = arr.indexOf(val);
				if (index > -1) {
					arr.splice(index, 1);
				}	
			},
			//多选确定
			MultipleOk(){
				if(!this.cuurSubject.isAnswer){
					this.singlecorrect = this.PublicJs.Decrypt(this.cuurSubject.que_answer)
					var optionArr = ["A","B","C","D","E","F"];
					var finalArr = []
					for(var i=0;i<optionArr.length;i++){
						for(var j=0;j<this.cuurChose.length;j++){
							if(optionArr[i] == this.cuurChose[j]){
								finalArr.push(optionArr[i])
							}
						}
					}
					var errorArr = []
					for(var i=0;i<this.cuurChose.length;i++){
						if(this.singlecorrect.indexOf(this.cuurChose[i]) == -1){
							errorArr.push(this.cuurChose[i])
						}
					}
					if(errorArr.length){
						this.singleerror = errorArr.join(",");
					}
					this.cuurSubject.isAnswer = true;
					this.cuurSubject.choseAnswer = finalArr.join(",");
					this.subjectData[this.cuurIndex-1].isAnswer = true;
					this.subjectData[this.cuurIndex-1].choseAnswer = finalArr.join(",");
					this.cuurChose = [];
				}
			},
			//默认选中
			defaultSubjectInfo(){
				if(this.cuurSubject.isAnswer){
					if(this.cuurSubject.type_text == "多选题"){
						this.singlecorrect = this.PublicJs.Decrypt(this.cuurSubject.que_answer);
						var errorArr = []
						for(var i=0;i<this.cuurSubject.choseAnswer.length;i++){
							if(this.singlecorrect.indexOf(this.cuurSubject.choseAnswer[i]) == -1){
								errorArr.push(this.cuurSubject.choseAnswer[i])
							}
						}
						if(errorArr.length){
							this.singleerror = errorArr.join(",");
						}
					}else{
						this.singlecorrect = this.PublicJs.Decrypt(this.cuurSubject.que_answer);
						if(this.singlecorrect != this.cuurSubject.choseAnswer){
							this.singleerror = this.cuurSubject.choseAnswer
						}
					}
				}
			},
			//返回
			goBack(){
				this.$emit('update:titleSync', "list");
			}
		},
		created() {
			//获取练习题目
			this.getExerciseData()
		}
	}
</script>

<style scoped="scoped">
	.goBack{
		font-size: 14px;
		color: #4E6FF1;
		position: absolute;
		right: 35px;
		top: 0;
		cursor: pointer;
	}
	.goBack:hover{
		opacity: 0.8;
	}
	.subjectInfo{
		padding: 35px;
	}
	.subjectHeader{
		min-height: 25px;
		font-size: 14px;
		color: #101e18;
		line-height: 25px;
	}
	/* .subjectHeader::after{
		content: "";
		display: block;
		clear: both;
	} */
	.subjectType{
		display: inline-block;
		height: 25px;
		padding: 0px 7px;
		background-color: #2B54A9;
		font-size: 12px;
		color: #ffffff;
		border-radius: 3px;
		line-height: 25px;
		margin-right: 10px;
	}
	.subjectIndex{
		font-size: 12px;
		color: #999999;
		line-height: 25px;
		display: inline-block;
		margin-right: 10px;
	}
	.subjectIndex .indexColor{
		color: #ff771e;
	}
	/* .subjectTitle{
		font-size: 14px;
		color: #101e18;
		line-height: 25px;
		display: inline-block;
	} */
	.optionList{
		padding: 21px 25px 25px;
		border-bottom: 1px solid #e2e2e2;
		position: relative;
	}
	.optionItem{
		height: 16px;
		line-height: 16px;
		margin-bottom: 19px;
		font-size: 14px;
		color: #101e18;
		cursor: pointer;
	}
	.optionItem::after,
	.answerList::after{
		content: "";
		clear: both;
		display: block;
	}
	.circular{
		width: 16px;
		height: 16px;
		border-radius: 8px;
		border: 1px solid #E2E2E2;
		float: left;
		margin-right: 10px;
		text-align: center;
		position: relative;
	}
	.csolid{
		width: 12px;
		height: 12px;
		border-radius: 6px;
		display: inline-block;
		position: absolute;
		top: 1px;
		left: 1px;
	}
	.optiontit{
		margin-left: 10px;
	}
	.optionItem:hover{
		color: #2B54A9;
	}
	.answerList{
		height: 20px;
	}
	.userAnswerPath,
	.rightAnswerPath{
		float: left;
		font-size: 14px;
		color: #101e18;
	}
	.userAnswerPath{
		margin-right: 100px;
	}
	.correct,
	.userAnswerPath .correctA,
	.rightAnswerPath span{
		color: #5EC513;
	}
	.correct .circular{
		border: 1px solid #5EC513;
	}
	.correct .csolid{
		background-color: #5EC513;
	}
	.error,
	.userAnswerPath .errorA{
		color: #EA3410;
	}
	.error .circular{
		border: 1px solid #EA3410;
	}
	.error .csolid{
		background-color: #EA3410;
	}
	.stepBtnPath{
		margin-top: 20px;
	}
	.btnList{
		display: inline-block;
		background-color: #2B54A9;
		color: #ffffff;
		cursor: pointer;
		width: 90px;
		height: 35px;
		border-radius: 3px;
		text-align: center;
		line-height: 35px;
		font-size: 16px;
		margin-right: 10px;
	}
	.btnList:hover{
		opacity: 0.8;
	}
	.btnList.noclick{
		background-color: #efefef;
		color: #666666;
		cursor: no-drop;
	}
	.showThis{
		float: right;
		cursor: pointer;
		font-size: 14px;
		color: #666666;
	}
	.showThis:hover{
		color: #40AFFE;
	}
	.questionsNumberPath{
		margin-top: 40px;
		max-height: 320px;
		overflow: auto;
	}
	.questionsNumberPath::after{
		content: "";
		display: block;
		clear: both;
	}
	.numItem{
		float: left;
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
		margin: 0px 3px 6px 0px;
		box-sizing: border-box;
		border-radius: 3px;
		font-size: 14px;
		background-color: #ffffff;
		border: 1px solid #e2e2e2;
		color: #666666;
	}
	.numItem.numError{
		background-color: #ea3410;
		border: 1px solid #ea3410;
		color: #ffffff;
	}
	.numItem.numCorrect{
		background-color: #5ec513;
		border: 1px solid #5ec513;
		color: #ffffff;
	}
	.numItem:hover,
	.numItem.numNow{
		border: 1px solid #2B54A9;
	}
	.multipleItem{
		height: 16px;
		line-height: 16px;
		margin-bottom: 19px;
		font-size: 14px;
		color: #101e18;
		cursor: pointer;
	}
	.multipleItem .circular{
		border-radius: 3px;
	}
	.multipleItem .csolid{
		border-radius: 2px;
	}
	.multipleItem.cuurChose{
		color: #2B54A9;
	}
	.multipleItem.cuurChose .circular{
		border: 1px solid #3DCF94;
	}
	.multipleItem.cuurChose .csolid{
		background-color: #3DCF94;
	}
	.multipleItem.correctM{
		color: #5ec513;
	}
	.multipleItem.correctM .circular{
		border: 1px solid #5ec513;
	}
	.multipleItem.correctM .csolid{
		background-color: #5ec513;
	}
	.multipleItem.errorM{
		color: #ea3410;
	}
	.multipleItem.errorM .circular{
		border: 1px solid #ea3410;
	}
	.multipleItem.errorM .csolid{
		background-color: #ea3410;
	}
	.okBtnStyle{
		position: absolute;
		width: 90px;
		height: 35px;
		background-color: #2B54A9;
		color: #ffffff;
		cursor: pointer;
		border-radius: 3px;
		text-align: center;
		line-height: 35px;
		font-size: 16px;
		right: 25px;
		bottom: 25px;
	}
	.okBtnStyle:hover{
		opacity: 0.8;
	}
</style>
